import React, { FC, useEffect, useRef } from 'react';
import EventHeader from '../../display/event-header';
import { useAppSelector } from '../../../store';
import { CrossRed1Icon, SuccessGreenIcon, ErrorRedIcon, SyncIcon, UnsyncIcon } from '../../../assets';
import { PendingTicketState } from '../../../service/db-service';
import { showTicketAttendeeName } from '../../../util/us-utils';
import {
  Box,
  LinearProgress,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Typography,
  useTheme,
} from '@material-ui/core';
import { TicketCount } from '../../display/ticket-count';
import { useDispatch } from 'react-redux';
import {
  clearSyncPendingTickets,
  retrievePendingTickets,
  syncPendingTickets,
} from '../../../store/thunks/ticket-thunks';
import ButtonIcon from '../../display/button-icon';
import { cleanPendingTickets } from '../../../store/slices/ticket-slice';
import { PENDING_TICKETS_LIMIT } from '../../../model/constants';
import PendingTicketsError from './pending-tickets-error';
import { selectIsMultipleEvents, selectSelectedEvent } from '../../../store/selectors';

const useStyles = makeStyles(() => ({
  listItem: {
    padding: '1.25rem',
    cursor: 'pointer',
    textTransform: 'uppercase',
  },
  listIcon: {
    justifyContent: 'flex-end',
    minWidth: 0,
  },
  primaryText: {
    fontWeight: 700,
    marginRight: '0.625rem',
  },
}));

export const CheckinSyncPage: FC = () => {
  const { pendingTickets, loading } = useAppSelector((state) => state.ticket);
  const event = useAppSelector(selectSelectedEvent);
  const isMultipleEvents = useAppSelector(selectIsMultipleEvents);
  const classes = useStyles();
  const dispatch = useDispatch();
  const theme = useTheme();
  const offsetRef = useRef(0);
  const lastRef = useRef(0);

  useEffect(() => {
    dispatch(cleanPendingTickets());
    dispatch(retrievePendingTickets({ offset: offsetRef.current, limit: PENDING_TICKETS_LIMIT }));
  }, []);

  const onScroll = (e) => {
    const scrollTop = e.target.scrollTop;
    const scrollHeight = e.target.scrollHeight;
    const offsetHeight = e.target.offsetHeight;

    if (offsetHeight + scrollTop >= scrollHeight && lastRef.current !== scrollHeight) {
      offsetRef.current = offsetRef.current + PENDING_TICKETS_LIMIT;
      lastRef.current = scrollHeight;
      dispatch(retrievePendingTickets({ offset: offsetRef.current, limit: PENDING_TICKETS_LIMIT }));
    }
  };

  return (
    <>
      <Box display='flex' flexDirection='column' justifyContent='space-between' height='100%'>
        <Box>
          <EventHeader
            event={event}
            goBackLink={`/event/${event?.id}`}
            subtitle='Check-In Synchronization'
            isMultipleEvents={isMultipleEvents}
          />
          <Box paddingTop={1}>{loading && <LinearProgress />}</Box>
        </Box>
        <Box onScroll={onScroll} height='100%' overflow='auto'>
          <List>
            {pendingTickets.length > 0 &&
              pendingTickets.map((pending, index) => (
                <React.Fragment key={index}>
                  <ListItem divider className={classes.listItem}>
                    <ListItemText
                      primary={
                        <>
                          <Box display='flex' alignItems='center'>
                            <TicketCount count={1} />
                            <Typography variant='body2' className={classes.primaryText}>
                              {showTicketAttendeeName(pending.ticket)} - {pending.ticket.id}
                            </Typography>
                          </Box>
                          <Box paddingLeft='35px'>
                            <Typography variant='body2'>Barcode: {pending.ticket.barcode}</Typography>
                          </Box>
                        </>
                      }
                    />
                    <ListItemIcon className={classes.listIcon}>
                      {pending.state === PendingTicketState.PENDING && <UnsyncIcon />}
                      {pending.state === PendingTicketState.SYNCED && <SuccessGreenIcon />}
                      {pending.state === PendingTicketState.ERROR && <ErrorRedIcon />}
                    </ListItemIcon>
                  </ListItem>

                  {pending.state === PendingTicketState.ERROR && <PendingTicketsError pending={pending} />}
                </React.Fragment>
              ))}
          </List>
        </Box>
        <Box paddingLeft={2} paddingRight={2} paddingBottom={2}>
          <ButtonIcon
            label='Synchronize All'
            variant='contained'
            btnStyle={{ color: '#fff', backgroundColor: theme.palette.info.main }}
            onClick={() => dispatch(syncPendingTickets())}
          >
            <SyncIcon />
          </ButtonIcon>
          <ButtonIcon
            label='Clear Synchronized Data'
            btnStyle={{ color: '#F44336', backgroundColor: '#EFEFEF', borderColor: '#F44336' }}
            onClick={() => dispatch(clearSyncPendingTickets())}
          >
            <CrossRed1Icon />
          </ButtonIcon>
        </Box>
      </Box>
    </>
  );
};
