import { GenericMessage, LocalStorageConf, TICKETS_LIMIT } from './../model/constants';
import { ApiError } from './../model/common-types';
import { LoginReq } from './../model/tsp/user';
import { CheckinReq, CheckinResponse } from './../model/tsp/checkin';
import axios, { AxiosError } from 'axios';
import { EventQueryRequest, EventResponse } from '../model/tsp/event';
import { FindOrdersQuery, OrderByIdResponse, SearchOrders } from '../model/tsp/order';
import { SettingsResponse } from '../model/tsp/settings';
import { LoginResponse } from '../model/tsp/user';
import { userLogout } from '../store/slices/user-slice';
import { TicketResponse } from '../model/tsp/ticket';
import { TicketTypeResponse } from '../model/tsp/ticket-type';

let store;
export const getApiUrl = (): string | null => localStorage.getItem(LocalStorageConf.ApiUrl);
export const getToken = (): string | null => localStorage.getItem(LocalStorageConf.Token);

export const setupApi = (_store): void => {
  store = _store;

  axios.interceptors.request.use(
    (config) => {
      if (!config?.url?.endsWith('/v1/tokens/without-key')) {
        const token = getToken();
        if (token && config.headers) {
          config.headers['Authorization'] = 'Bearer ' + token;
        }
      }

      return config;
    },
    (error) => {
      return Promise.reject(error);
    },
  );
  axios.interceptors.response.use(
    (res) => {
      return res;
    },
    async (err: AxiosError): Promise<ApiError> => {
      if (err.config.url !== '/v1/tokens/without-key' && err.response && err.response.status === 401) {
        store.dispatch(userLogout());
      }

      const apiErrorRes = {} as ApiError;

      if (err?.response) {
        apiErrorRes.code = err.response.status;
        apiErrorRes.message = err.response.data;
      } else {
        apiErrorRes.code = 400;
        apiErrorRes.message = err?.message ? err.message : GenericMessage.UnknownError;
      }

      throw apiErrorRes;
    },
  );
};

export const apiLogin = async (body: LoginReq): Promise<LoginResponse> => {
  const res = await axios.post<LoginResponse>(`${getApiUrl()}/api/v1/tokens/without-key`, body);
  return res.data;
};

export const apiLoadSettings = async (): Promise<SettingsResponse> => {
  const res = await axios.get<SettingsResponse>(`${getApiUrl()}/api/v1/settings`);
  return res.data;
};

export const apiFindEvents = async (query: EventQueryRequest): Promise<EventResponse> => {
  const params = {
    includeOffSale: false,
    offset: 0,
    limit: 200,
    ...query,
  };
  const res = await axios.get<EventResponse>(`${getApiUrl()}/api/v1/events`, {
    params,
  });
  return res.data;
};

export const apiFindOrders = async (query: FindOrdersQuery): Promise<SearchOrders> => {
  const params = {
    ...query,
  };
  const res = await axios.get<SearchOrders>(`${getApiUrl()}/api/v1/orders`, {
    params,
  });
  return res.data;
};

export const apiGetOrderById = async (orderId: string): Promise<OrderByIdResponse> => {
  const res = await axios.get<OrderByIdResponse>(`${getApiUrl()}/api/v1/orders/${orderId}`);
  return res.data;
};

export const apiCheckinTicket = async (query: CheckinReq): Promise<CheckinResponse> => {
  const body = {
    barcode: query.barCode,
    eventIds: query.eventIds,
    bibNumber: '',
    chipNumber: '',
  };

  const res = await axios.post<CheckinResponse>(`${getApiUrl()}/api/v1/tickets/check-in`, body);
  return res.data;
};

export const apiGetTicketsByEventId = async (
  eventId: string,
  lastUpdate?: number,
  foundPageCallback?: (ticketsResp: TicketResponse) => Promise<void>,
): Promise<TicketResponse> => {
  let url = `${getApiUrl()}/api/v1/tickets?eventId=${eventId}`;
  if (lastUpdate) {
    url += `&lastUpdate=${lastUpdate}`;
  }

  const ticketsRes = await apiGetTicketsByEventIdPaginated(url);
  await foundPageCallback?.(ticketsRes);
  let count = ticketsRes.count;
  if (ticketsRes.data && ticketsRes.totalCount > TICKETS_LIMIT) {
    const totalPages = Math.ceil(ticketsRes.totalCount / TICKETS_LIMIT);

    for (let i = 1; i < totalPages; i++) {
      const res = await apiGetTicketsByEventIdPaginated(url, i * TICKETS_LIMIT, TICKETS_LIMIT);
      await foundPageCallback?.(res);
      count += res.count;
      res.data.forEach((ticket) => {
        ticketsRes.data.push(ticket);
      });
    }
  }

  ticketsRes.count = count;

  return ticketsRes;
};

export const apiGetTicketsByEventIdPaginated = async (
  url: string,
  offset = 0,
  limit = TICKETS_LIMIT,
): Promise<TicketResponse> => {
  const res = await axios.get<TicketResponse>(`${url}&offset=${offset}&limit=${limit}`);

  return res.data;
};

export const apiGetTicktsTypes = async (eventId: string): Promise<TicketTypeResponse> => {
  const res = await axios.get<TicketTypeResponse>(`${getApiUrl()}/api/v1/events/${eventId}/ticket-types`);
  return res.data;
};
