import { RootState } from '../index';
import { apiFindEvents } from '../../service/api-service';
import { EventQueryRequest, Event } from '../../model/tsp/event';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { ApiError } from '../../model/common-types';
import { GenericMessage } from '../../model/constants';
import { db } from '../../service/db-service';

export const findEvents = createAsyncThunk<Event[], EventQueryRequest, { rejectValue: ApiError }>(
  'loadEvents',
  async (query, thunkApi) => {
    try {
      const appStore = thunkApi.getState() as RootState;
      if (appStore.settings.offlineMode) {
        const offlineEvents = await db.getOfflineEvent();
        return offlineEvents.map((offlineEvent) => offlineEvent.event);
      }

      const res = await apiFindEvents(query);
      if (res.success) {
        return res.data;
      } else {
        return thunkApi.rejectWithValue({ code: 400, message: res.message || GenericMessage.UnknownError });
      }
    } catch (error) {
      return thunkApi.rejectWithValue({
        code: 400,
        message: error?.message || GenericMessage.UnknownError,
      } as ApiError);
    }
  },
);
