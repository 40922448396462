
import React, { FC, useEffect, useState } from 'react';
import { Box, Button, Divider, Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import { Link, useHistory, useParams } from 'react-router-dom';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { LoaderModal } from '../display/loader-modal';
import { EventCalendar } from '../display/event-calendar';
import { CheckInOverrideModal } from '../display/check-in-override-modal';
import { fixDateIfApply } from '../../util/date-utils';
import { useDispatch } from 'react-redux';
import { PlayFunction } from 'use-sound/dist/types';
import { useAppSelector } from '../../store';
import { cleanCheckin } from '../../store/slices/ticket-slice';
import { checkinTicket } from '../../store/thunks/ticket-thunks';
import { KeyboardReader } from '../display/keyboard-reader';
import { selectEventsToScan, selectSelectedEvent } from '../../store/selectors';
import SuccessNotification from '../display/success-notification';
import ConfirmationModal from '../display/confirmation-modal';
import { openModal } from '../../store/slices/modalSlice';
import InfoIcon from '@material-ui/icons/Info';
import { FONT_BOLD, OrderMessage } from '../../model/constants';
import { ProcessState } from '../../model/tsp/order';

const useStyles = makeStyles((theme: Theme) => ({
  arrowIcon: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: '0.9375rem',
  },
  padding: {
    paddingTop: '1rem',
    paddingBottom: '1rem',
  },
  successContainer: {
    width: '100%',
    padding: '5px',
    borderRadius: '3px',
    backgroundColor: theme.palette.success.main,
    marginTop: '10px',
    display: 'flex',
    justifyContent: 'center',
    gap: '5px',
    color: '#ffffff',
    boxShadow: 'none',
    fontSize: '12px',
    fontWeight: 'bold',
    alignItems: 'center',
    marginBottom: '1rem',
  },
  successIcon: {
    marginRight: '1.5rem',
    marginTop: '2px',
  },
  detailsContainer: {
    marginLeft: '1.5rem',
    display: 'flex',
    alignItems: 'center',
    gap: '0.5rem',
  },
  checkinText: {
    fontSize: '15px',
  },
  checkInButton: {
    border: '1px solid rgba(0, 0, 0, 0.87)',
    fontFamily: FONT_BOLD,
    textTransform: 'initial',
  },
  infoIcon: {
    marginRight: '0.25rem',
  },
  successMessage: {
    paddingTop: '1rem',
    paddingBottom: '1rem',
    color: 'green',
    fontWeight: 'bold',
  },
}));

interface LaserScanPageProps {
  playSuccess: PlayFunction;
  playError: PlayFunction;
}

export const LaserScanPage: FC<LaserScanPageProps> = ({ playError, playSuccess }) => {
  const classes = useStyles();
  const { eventId } = useParams<{ eventId: string }>();
  const dispatch = useDispatch();
  const history = useHistory();
  const selectedEvent = useAppSelector(selectSelectedEvent);
  const toScan = useAppSelector(selectEventsToScan);
  const { loading, errorMessage, checkin } = useAppSelector((state) => state.ticket);
  const selectedOrder = useAppSelector((state) => state.order.selectedOrder);
  const { offlineMode } = useAppSelector((state) => state.settings);
  const [overrideModalOpen, setOverrideModalOpen] = useState(false);
  const [barCodeSelected, setBarCodeSelected] = useState('');
  const allTicketsToScan = selectedOrder?.tickets.data.filter(
    (ticket) => ticket.processState === ProcessState.Purchased && parseInt(ticket.availableScans) !== parseInt(ticket.usedScans),
  );
  const [checkInEntireOrder, setCheckInEntireOrder] = useState(false);
  const [playedSuccess, setPlayedSuccess] = useState(false);
  let totalTicketsToScan = 0;
  let ticketName = '';
  let orderTicket;
  let scanned = '';

  useEffect(() => {
    dispatch(cleanCheckin());

    return () => {
      dispatch(cleanCheckin());
    };
  }, []);

  useEffect(() => {
    if (checkin?.valid && (!checkInEntireOrder || !playedSuccess)) {
      playSuccess();
      setPlayedSuccess(true);
    }
  }, [checkin, checkInEntireOrder, playedSuccess]);

  useEffect(() => {
    if (errorMessage) {
      playError();
    }
  }, [errorMessage]);

  if (allTicketsToScan) {
    totalTicketsToScan = allTicketsToScan?.filter(ticket => ticket.barcode !== barCodeSelected).length;
  }

  const checkinScanFirstTime = (barCode: string) => {
    setBarCodeSelected(barCode);
    checkinScan(barCode);
  };

  const checkinScan = (barCode: string) => {
    const query = {
      barCode,
      eventIds: toScan.map((event) => event.id),
    };

    if (barCode?.length > 0) {
      setOverrideModalOpen(false);
      dispatch(checkinTicket(query));
      window.scrollTo(0, 80);
    }
    setCheckInEntireOrder(false);
    setPlayedSuccess(false);
  };

  const checkinEntireOrder = (barCode: string) => {
    const query = {
      barCode,
      eventIds: toScan.map((event) => event.id),
    };

    if (barCode?.length > 0) {
      setOverrideModalOpen(false);
      dispatch(checkinTicket(query));
    }
  };

  const scanAllTickets = async () => {
    try {
      if (allTicketsToScan) {
        for (const ticket of allTicketsToScan) {
          if (barCodeSelected !== ticket.barcode) {
            await checkinEntireOrder(ticket.barcode);
          }
        }
      }
      window.scrollTo(0, 80);
      setCheckInEntireOrder(true);
      setPlayedSuccess(false);
    } catch (error) {
      console.error('Error', error);
    }
  };

  const confirmCheckIn = () => {
    dispatch(openModal('scanAll'));
  };
  if (checkin?.valid) {
    orderTicket = selectedOrder?.tickets?.data?.find((ot) => ot.barcode === checkin?.barcode);
    if (orderTicket) {
      ticketName =
        orderTicket.firstName === 'Box' && orderTicket.lastName == 'Office'
          ? 'Box Office'
          : orderTicket.partyMember + ' ' + orderTicket.partyMemberLastName;

      if (orderTicket?.scanType === 'package' && orderTicket?.availableScans && orderTicket?.usedScans) {
        scanned = ` - ${orderTicket.usedScans}/${orderTicket.availableScans}`;
      }
      if (checkInEntireOrder) {
        ticketName = OrderMessage.EntireOrderChecked;
      }
    }
  }

  const label = checkInEntireOrder ? `${ticketName}` : `${ticketName} Checked In ${scanned}`;

  const goToScannedEvent = () => {
    history.push(`/event/${eventId}/order/${checkin.orderId}/barcode/${checkin.barcode}`);
  };

  return (
    <Box paddingTop='1.5rem' margin='0 1.125rem'>
      {selectedEvent && (
        <Box width='100%' paddingBottom='1.5rem'>
          <Link to={`/event/${eventId}`}>
            <Grid container alignItems='center' style={{ flexWrap: 'nowrap' }}>
              <Grid item xs={1}>
                <ArrowBackIosIcon className={classes.arrowIcon} />
              </Grid>
              <Grid item xs={3}>
                <EventCalendar weekday={false} date={fixDateIfApply(new Date(selectedEvent.start * 1000))} />
              </Grid>
              <Grid item xs={9}>
                <Typography variant='h6'>{selectedEvent.title}</Typography>
                <Typography variant='subtitle2'>Check-In | Ready to scan.</Typography>
              </Grid>
            </Grid>
          </Link>
        </Box>
      )}
      <Divider />

      <Box display='flex' paddingTop='0.5rem' textAlign='center' justifyContent='center' flexDirection='column'>
        <Box>
          {!loading && orderTicket && <SuccessNotification goToScannedEvent={goToScannedEvent} label={label} />}
          {errorMessage && (
            <Typography color='error' className={classes.padding}>
              {errorMessage}
            </Typography>
          )}
        </Box>
        <Button
          variant='outlined'
          onClick={confirmCheckIn}
          fullWidth
          style={{
            background: checkin && !checkInEntireOrder && totalTicketsToScan >= 1 ? 'skyblue' : 'transparent',
          }}
          className={classes.checkInButton}
          disabled={checkin && !checkInEntireOrder && totalTicketsToScan >= 1 ? false : true}
        >
          <InfoIcon fontSize='small' className={classes.infoIcon} />
          {'Check in all tickets'}
        </Button>
        <KeyboardReader onKeyboardReaderEnter={checkinScanFirstTime} />
      </Box>
      <ConfirmationModal
        title='Check In All Tickets'
        description={`Do you want to Check In ${totalTicketsToScan} tickets? `}
        onConfirmCallback={scanAllTickets}
      />
      <LoaderModal loading={loading && !offlineMode} />
      <CheckInOverrideModal open={overrideModalOpen} closeModal={() => setOverrideModalOpen(false)} />
    </Box>
  );
};
