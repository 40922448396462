import { combineReducers } from '@reduxjs/toolkit';
import settingsReducer from './slices/settings-slice';
import userReducer from './slices/user-slice';
import eventReducer from './slices/event-slice';
import orderReducer from './slices/order-slice';
import ticketReducer from './slices/ticket-slice';
import modalConfirmationSlice from './slices/modalSlice';

const rootReducer = combineReducers({
  settings: settingsReducer,
  user: userReducer,
  event: eventReducer,
  order: orderReducer,
  ticket: ticketReducer,
  modalConfirmation: modalConfirmationSlice,
});

export type RootReducerType = typeof rootReducer;

export default rootReducer;
