import { db, ordersDBService, ticketsDBService, KeyIndex } from '.';
import { Order } from '../../model/tsp/order';
import { Ticket } from '../../model/tsp/ticket';

type QueryKey =
  | KeyIndex.PARTY_FIRST_NAME_INDEX
  | KeyIndex.PARTY_LAST_NAME_INDEX
  | KeyIndex.BARCODE_INDEX
  | KeyIndex.GROUP_INDEX;

export class AttendeesService {
  async getAttendees(key: QueryKey, value: string, limit?: number): Promise<Order[]> {
    const tickts = (await db.transaction('r', db.tickets, async () => {
      return await db.tickets
        .where(key)
        .startsWithAnyOfIgnoreCase(value)
        .limit(limit || 10)
        .toArray();
    })) as Ticket[];
    return ordersDBService.transformTicketsToOrders(tickts);
  }

  async findAttendee(word: string): Promise<Order[]> {
    const searchWord = word.split(' ');
    if (searchWord.length > 1) {
      const tickets = [
        ...(await ticketsDBService.findByFirstAndLastName(searchWord[0], searchWord[1])),
        ...(await ticketsDBService.findByFirstAndLastName(searchWord[1], searchWord[0])),
      ];
      return ordersDBService.transformTicketsToOrders(tickets);
    } else {
      let orders: Order[] = [];
      const byFirstName = await this.getAttendees(KeyIndex.PARTY_FIRST_NAME_INDEX, word);
      if (byFirstName.length > 0) {
        orders = [...orders, ...byFirstName];
      }
      const byLastName = await this.getAttendees(KeyIndex.PARTY_LAST_NAME_INDEX, word);
      if (byLastName.length > 0) {
        orders = [...orders, ...byLastName];
      }

      if (orders.length > 0) {
        return orders;
      }

      orders = await this.getAttendees(KeyIndex.GROUP_INDEX, word, 1);
      if (orders.length > 0) {
        return orders;
      }

      orders = await this.getAttendees(KeyIndex.BARCODE_INDEX, word, 1);
      if (orders.length > 0) {
        return orders;
      }

      return orders;
    }
  }
}
