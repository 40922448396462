/* eslint-disable sonarjs/cognitive-complexity */
import React, { FC, useEffect, useState } from 'react';
import { Box, List, makeStyles, Typography } from '@material-ui/core';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Order } from '../../model/tsp/order';
import { SearchInput } from '../display/search-input';
import { LoaderModal } from '../display/loader-modal';
import { countValidTickets, isMatching } from '../../util/order-util';
import { OrderListItem } from '../display/order-list-item';
import { useAppSelector } from '../../store';
import { cleanOrders, selectOrder } from '../../store/slices/order-slice';
import { findOrders } from '../../store/thunks/order-thunks';
import { Ticket } from '../../model/tsp/ticket';
import EventHeader from '../display/event-header';
import { showTicketAttendeeName } from '../../util/us-utils';
import { selectEventsToScan, selectIsMultipleEvents, selectSelectedEvent } from '../../store/selectors';

const useStyles = makeStyles(() => ({
  arrowIcon: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: '0.9375rem',
  },
  list: {
    margin: '0 1.25rem',
    paddingTop: 0,
    textTransform: 'uppercase',
  },
  marginTop: {
    marginTop: '1.25rem',
  },
}));

export const AttendeesPage: FC = () => {
  const classes = useStyles();
  const { eventId } = useParams<{ eventId: string }>();
  const dispatch = useDispatch();
  const history = useHistory();

  const selectedEvent = useAppSelector(selectSelectedEvent);
  const isMultipleEvents = useAppSelector(selectIsMultipleEvents);
  const toScan = useAppSelector(selectEventsToScan);

  const { orders, loading } = useAppSelector((state) => state.order);
  const [searchText, setSearchText] = useState<string>('');
  const [error, setError] = useState(null);
  const keywords = ['Name', 'Order Number'];

  const handleSubmit = (search: string) => {
    setError(null);
    setSearchText(search);
    const eventIds = isMultipleEvents ? toScan.map((event) => event.id) : [eventId];
    dispatch(findOrders({ eventIds, search }));
  };

  const goToOrderDetails = (order: Order) => {
    dispatch(selectOrder(order.id));
    history.push(`/event/${eventId}/order/${order.id}/attendees`, { attendeePage: true });
  };

  useEffect(() => {
    dispatch(cleanOrders());
  }, []);

  if (!selectedEvent) {
    return null;
  }

  return (
    <>
      <EventHeader
        event={selectedEvent}
        goBackLink={`/event/${eventId}`}
        subtitle='Attendee List'
        isMultipleEvents={isMultipleEvents}
      />
      <Box marginTop={2}>
        <SearchInput placeholder='Find Attendees...' keywordOptions={keywords} handleSubmit={handleSubmit} />
      </Box>
      {error !== null && (
        <Typography color='error' align='center' className={classes.marginTop}>
          {error}
        </Typography>
      )}
      {orders !== null && (
        <List className={classes.list}>
          {orders.map((order, index) => {
            const ticket: Ticket =
              order.tickets.data.find(
                (t: Ticket) =>
                  isMatching(t.partyMemberLastName, searchText) ||
                  isMatching(t.partyMember, searchText) ||
                  isMatching(t.partyMember + ' ' + t.partyMemberLastName, searchText) ||
                  isMatching(t.barcode, searchText) ||
                  isMatching(t.group, searchText),
              ) || order.tickets.data[0];

            return (
              <OrderListItem
                key={index}
                primaryText={showTicketAttendeeName(ticket)}
                secondaryText={selectedEvent.venue}
                ticketCount={countValidTickets(order)}
                handleClick={() => goToOrderDetails(order)}
                icon={<ArrowForwardIosIcon className={classes.arrowIcon} />}
              />
            );
          })}
        </List>
      )}
      <LoaderModal loading={loading} text={`Searching attendee data for '${searchText}'`} />
    </>
  );
};
