import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../../store';
import { setOfflineMode } from '../../../store/thunks/settings-thunks';
import { OffLineIcon, OnLineIcon, SyncBlue2Icon } from '../../../assets';
import ButtonIcon from '../button-icon';
import { Box, createStyles, makeStyles } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { showOnlineModal } from '../../../store/slices/settings-slice';
import useInternet from '../../../hooks/internet-hook';

interface OfflineButtonsProps {
  ids: string[];
}

const useStyles = makeStyles(() =>
  createStyles({
    disabled: {
      '& path': {
        fill: '#AAA',
      },
    },
  }),
);

const OfflineButtons: FC<OfflineButtonsProps> = ({ ids }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { hasInternet } = useInternet();
  const { offlineMode, loading } = useAppSelector((state) => state.settings);

  return (
    <>
      {offlineMode ? (
        <ButtonIcon
          label='Back to Online Mode and Sync Data'
          btnStyle={{ color: '#000', borderColor: '#000', marginTop: '0px' }}
          onClick={() => dispatch(showOnlineModal(true))}
          disabled={loading || !hasInternet}
        >
          <OnLineIcon className={!hasInternet && classes.disabled} />
        </ButtonIcon>
      ) : (
        <ButtonIcon
          label='Go on Offline Mode'
          btnStyle={{ marginTop: '0px' }}
          onClick={() => dispatch(setOfflineMode(ids))}
          disabled={loading || !hasInternet}
        >
          <OffLineIcon className={!hasInternet && classes.disabled} />
        </ButtonIcon>
      )}

      {offlineMode ? (
        <Box marginTop={1}>
          <ButtonIcon
            label='Synchronization'
            btnStyle={{ color: 'rgba(33, 150, 243, 1)', borderColor: 'rgba(33, 150, 243, 1)', marginTop: '0px' }}
            onClick={() => history.push('/checkin-sync')}
            disabled={loading}
          >
            <SyncBlue2Icon />
          </ButtonIcon>
        </Box>
      ) : null}
    </>
  );
};

export default OfflineButtons;
