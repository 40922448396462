import { Box, Collapse, Grid, makeStyles, Typography } from '@material-ui/core';
import React, { FC, useState } from 'react';
import { ProcessState } from '../../model/tsp/order';
import { CheckCircle } from './check-circle';
import { OrderListItem } from './order-list-item';
import { MoreDownIcon, MoreUpIcon } from '../../assets';
import { useDispatch } from 'react-redux';
import { checkinTicket } from '../../store/thunks/ticket-thunks';
import { useAppSelector } from '../../store';
import { calculateAge } from '../../util/order-util';
import { OrderTicketItem } from './order-ticket-item';
import { QuestionsView } from './questions';
import { Ticket } from '../../model/tsp/ticket';
import { selectSelectedEvent } from '../../store/selectors';

interface OrderTicketProps {
  isOpen: boolean;
  orderTicket: Ticket;
}

const useStyles = makeStyles(() => ({
  attendeeContainer: {
    borderTop: '1px solid rgba(0, 0, 0, 0.1)',
  },
  attendeeInfo: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '11px 0px 11px 15px',
    alignItems: 'center',
  },
  attendeeTitle: {
    fontSize: '14px',
  },
}));

const OrderTicketComp: FC<OrderTicketProps> = ({ orderTicket, isOpen }) => {
  const dispatch = useDispatch();
  const selectedEvent = useAppSelector(selectSelectedEvent);
  const [open, setOpen] = useState<boolean>(isOpen);
  const classes = useStyles();

  const name =
    orderTicket.firstName === 'Box' && orderTicket.lastName == 'Office'
      ? 'Box Office'
      : orderTicket.partyMember + ' ' + orderTicket.partyMemberLastName;

  const checkIn = async (barCode: string) => {
    dispatch(checkinTicket({ eventIds: [selectedEvent.id], barCode }));
  };

  const getScannedTickets = (orderTicket: Ticket) => {
    if (parseInt(orderTicket.availableScans) > 1) {
      return `Scanned ${orderTicket.usedScans} / ${orderTicket.availableScans}`;
    }
    return '';
  };

  return (
    <>
      <OrderListItem
        checkedIn={orderTicket.processState === ProcessState.CheckedIn}
        primaryText={name}
        subtitle={orderTicket.id}
        secondaryText={getScannedTickets(orderTicket)}
        ticketCount={1}
        handleClick={() => checkIn(orderTicket.barcode)}
        icon={
          <CheckCircle
            checked={
              orderTicket.processState === ProcessState.CheckedIn ||
              (parseInt(orderTicket.availableScans) > 1 &&
                parseInt(orderTicket.availableScans) === parseInt(orderTicket.usedScans))
            }
          />
        }
      />
      <>
        <Box className={classes.attendeeInfo} onClick={() => setOpen(!open)}>
          <Typography className={classes.attendeeTitle}>Attendee Information</Typography>
          <Box>{open ? <MoreUpIcon /> : <MoreDownIcon />}</Box>
        </Box>
        <Collapse in={open}>
          <Box className={classes.attendeeContainer}>
            <Box paddingTop='1.5rem'>
              <Grid container spacing={1}>
                <OrderTicketItem
                  label='Age'
                  value={calculateAge(
                    orderTicket.partyMemberBirthYear,
                    orderTicket.partyMemberBirthMonth,
                    orderTicket.partyMemberBirthDay,
                  )}
                />
                <OrderTicketItem label='Phone' value={orderTicket.partyMemberPhone} />
                <OrderTicketItem label='Email' value={orderTicket.emailAddress} />
                <OrderTicketItem label='Address' value={orderTicket.address} />
                <OrderTicketItem label='Wave Time' value={orderTicket.waveTime?.formattedStartTime || ''} />
                <OrderTicketItem label='Ticket Name' value={orderTicket.ticketTypeName} />
                <OrderTicketItem label='Event' value={selectedEvent.title} />
                <OrderTicketItem label='Venue' value={selectedEvent.venue} />
              </Grid>
            </Box>
            <QuestionsView title='Questions:' questions={orderTicket.attendeeQuestions} />
          </Box>
        </Collapse>
      </>
    </>
  );
};

export default OrderTicketComp;
