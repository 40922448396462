import { apiFindOrders, apiGetOrderById } from '../../service/api-service';
import { FindOrders, OrderByIdResponse, SearchOrders } from '../../model/tsp/order';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { Order } from '../../model/tsp/order';
import { ApiError } from '../../model/common-types';
import { RootState } from '..';
import { attendeesDBService } from '../../service/db-service';

export const findOrders = createAsyncThunk<Order[], FindOrders, { rejectValue: ApiError }>(
  'findOrders',
  async (query, thunkApi) => {
    try {
      const state = thunkApi.getState() as RootState;
      if (state.settings.offlineMode) {
        return await attendeesDBService.findAttendee(query.search || '');
      }

      const { eventIds, ...resQuery } = query;
      const promises: Promise<SearchOrders>[] = [];

      eventIds?.forEach((eventId) => {
        promises.push(apiFindOrders({ ...resQuery, eventId }));
      });

      const listSearchResp = await Promise.all(promises);
      const orderPending: Promise<OrderByIdResponse>[] = [];

      for (const search of listSearchResp) {
        if (search.success) {
          for (let i = 0; i < search?.data?.length; i++) {
            const orderDetail = search.data[i];
            orderPending.push(apiGetOrderById(orderDetail.orderId));
          }
        }
      }

      const resp: OrderByIdResponse[] = await Promise.all(orderPending);
      return resp.filter((r) => r.success).map((r) => r.data);
    } catch (error) {
      return thunkApi.rejectWithValue(error as ApiError);
    }
  },
);

export const findOrdersById = createAsyncThunk<Order, string, { rejectValue: ApiError }>(
  'findOrdersById',
  async (id, thunkApi) => {
    try {
      const res = await apiGetOrderById(id);
      if (res.success) {
        return res.data;
      } else {
        return thunkApi.rejectWithValue({ code: 400, message: res.message });
      }
    } catch (error) {
      return thunkApi.rejectWithValue(error as ApiError);
    }
  },
);
