import { TicketTypeResponse } from './../../model/tsp/ticket-type';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { apiCheckinTicket, apiGetTicketsByEventId, apiGetTicktsTypes } from '../../service/api-service';
import { ApiError } from '../../model/common-types';
import { CheckinReq, CheckinResponse } from '../../model/tsp/checkin';
import { TicketMessages } from '../../model/constants';
import { TicketResponse } from '../../model/tsp/ticket';
import { RootState } from '..';
import { setSelectedOrder } from '../slices/order-slice';
import { findOrdersById } from './order-thunks';
import {
  ticketsDBService,
  checkinsDBService,
  ordersDBService,
  PendingTicket,
  PendingTicketState,
} from '../../service/db-service';
import { Order, ProcessState } from '../../model/tsp/order';
import { removePendingState, setLastUpdate, setSyncProcess, updatePendingState } from '../slices/ticket-slice';
import { v4 as uuidv4 } from 'uuid';
import { EventTicketType } from '../../model/tsp/ticket-type';
import { Event } from '../../model/tsp/event';

export const checkinTicket = createAsyncThunk<CheckinResponse, CheckinReq, { rejectValue: ApiError }>(
  'checkinTicket',
  async (query, thunkApi) => {
    try {
      const state = thunkApi.getState() as RootState;

      let checkinRes;

      if (state.settings.offlineMode) {
        checkinRes = await makeCheckinOffline(query, (order: Order) => {
          thunkApi.dispatch(setSelectedOrder(order));
        });
      } else {
        checkinRes = (await apiCheckinTicket(query)) as CheckinResponse;
        if (checkinRes.valid) {
          await thunkApi.dispatch(findOrdersById(checkinRes.orderId));
        }
      }

      if (!checkinRes.valid) {
        const message = getErrorMessage(checkinRes);
        return thunkApi.rejectWithValue({ code: 400, message });
      }
      return checkinRes;
    } catch (error) {
      return thunkApi.rejectWithValue(error as ApiError);
    }
  },
);

const makeCheckinOffline = async (
  query: CheckinReq,
  refreshOrderCallback: (order: Order) => void,
): Promise<CheckinResponse> => {
  const res = {} as CheckinResponse;

  const ticket = await ticketsDBService.getTicketByBarcode(query.barCode);

  if (!ticket) {
    res.valid = false;
    res.reason = TicketMessages.TicketNotFound;
  } else if (ticket.processState === ProcessState.Purchased && parseInt(ticket.availableScans) > 1) {
    if (parseInt(ticket.usedScans) < parseInt(ticket.availableScans)) {
      res.valid = true;
      res.orderId = ticket.group;
      res.barcode = ticket.barcode;

      ticket.usedScans = `${parseInt(ticket.usedScans) + 1}`;
      await ticketsDBService.updateTicket(ticket);
      const pendingTicket: PendingTicket = {
        ticket: ticket,
        id: ticket.id,
        state: PendingTicketState.PENDING,
        usedScans: ticket.usedScans,
        guid: uuidv4(),
      };
      await checkinsDBService.addPendingCheckin(pendingTicket);
      const order = await ordersDBService.getOrderById(ticket.group);
      refreshOrderCallback(order);
    } else {
      res.valid = false;
      res.reason = TicketMessages.AlreadyScannedError;
    }
  } else if (ticket.processState === ProcessState.Purchased) {
    res.valid = true;
    res.orderId = ticket.group;
    res.barcode = ticket.barcode;

    ticket.processState = ProcessState.CheckedIn;
    await ticketsDBService.updateTicket(ticket);
    await checkinsDBService.addPendingCheckin({
      ticket: ticket,
      id: ticket.id,
      state: 'pending',
      usedScans: ticket.usedScans,
    } as PendingTicket);
    const order = await ordersDBService.getOrderById(ticket.group);
    refreshOrderCallback(order);
  } else if (ticket.processState === ProcessState.CheckedIn) {
    res.valid = false;
    res.reason = TicketMessages.AlreadyScannedError;
  } else {
    res.valid = false;
    res.reason = TicketMessages.UnknownError;
  }

  return res;
};

const getErrorMessage = (checkinRes: CheckinResponse): string => {
  let message = checkinRes.reason;
  if (checkinRes.anotherEvent) {
    message = TicketMessages.AnotherEventError;
  } else if (checkinRes.anotherTimeSlot) {
    message = TicketMessages.AnotherTimeSlotError;
  } else if (checkinRes.duplicate && checkinRes.scanTime) {
    message = checkinRes.reason + ` ${checkinRes.usedScans}/${checkinRes.availableScans}`;
  } else if (!message || message === '') {
    message = TicketMessages.UnknownError;
  }
  return message;
};

type RetrieveTicketsType = {
  eventId: string;
  res: TicketResponse;
};
export const retrieveTicketsByEventId = createAsyncThunk<
  RetrieveTicketsType,
  string,
  { rejectValue: ApiError & { eventId: string } }
>('retrieveTicketsByEventId', async (eventId, thunkApi) => {
  try {
    const state = thunkApi.getState() as RootState;
    if (state.settings.offlineMode) {
      const res = {} as TicketResponse;

      const tickets = await ticketsDBService.getAllTickets();
      res.data = tickets;
      res.totalCount = tickets.length;

      return { eventId, res };
    }
    const res = (await apiGetTicketsByEventId(eventId)) as TicketResponse;
    const lastUpdate = Math.max(...res.data.map((ticket) => parseInt(ticket.lastUpdate)));
    thunkApi.dispatch(setLastUpdate(lastUpdate));

    return {
      eventId,
      res,
    };
  } catch (error) {
    return thunkApi.rejectWithValue({ ...(error as ApiError), eventId });
  }
});

export const retrieveTicketsTypes = createAsyncThunk<
  EventTicketType,
  string,
  { rejectValue: ApiError & { eventId: string } }
>('ticketsSlice/retrieveTicketsTypes', async (eventId, thunkApi) => {
  try {
    const state = thunkApi.getState() as RootState;
    if (state.settings.offlineMode) {
      const res = {} as EventTicketType;
      const ticketTypes = await ticketsDBService.findTicketTypesByEventId(eventId);
      res[eventId] = ticketTypes;
      return res;
    }
    const res = (await apiGetTicktsTypes(eventId)) as TicketTypeResponse;

    return {
      [eventId]: res.data.map((ticketType) => ({
        id: ticketType.id,
        name: ticketType.name,
      })),
    };
  } catch (error) {
    return thunkApi.rejectWithValue({ ...(error as ApiError), eventId });
  }
});

export interface PendingTicketReq {
  offset: number;
  limit: number;
}

export const retrievePendingTickets = createAsyncThunk<PendingTicket[], PendingTicketReq, { rejectValue: ApiError }>(
  'retrievePendingTickets',
  async ({ offset, limit }, thunkApi) => {
    try {
      return await checkinsDBService.getPendingTickets(offset, limit);
    } catch (error) {
      return thunkApi.rejectWithValue({ ...(error as ApiError) });
    }
  },
);

export const syncPendingTicket = createAsyncThunk<void, PendingTicket, { rejectValue: ApiError }>(
  'syncPendingTicket',
  async (pendingTicket, thunkApi) => {
    const pending: PendingTicket = { ...pendingTicket } as PendingTicket;
    try {
      const checkinRes = await apiCheckinTicket({
        barCode: pending.ticket.barcode,
        eventIds: [pending.ticket.eventId],
      });

      if (checkinRes.valid) {
        pending.state = PendingTicketState.SYNCED;
        pending.message = 'Checked in successfully';
      } else {
        const message = getErrorMessage(checkinRes);
        pending.state = PendingTicketState.ERROR;
        pending.message = message;
      }
    } catch (error) {
      pending.state = PendingTicketState.ERROR;
      pending.message = error.message;
    }
    await checkinsDBService.updatePendingTicket(pending);
    thunkApi.dispatch(updatePendingState(pending));
  },
);

export const syncPendingTickets = createAsyncThunk<void, void, { rejectValue: ApiError }>(
  'syncPendingTickets',
  async (_: void, thunkApi) => {
    try {
      const pendings = [
        ...(await checkinsDBService.getPendingTicketsByState(PendingTicketState.PENDING)),
        ...(await checkinsDBService.getPendingTicketsByState(PendingTicketState.ERROR)),
      ];

      for (const pending of pendings) {
        await thunkApi.dispatch(syncPendingTicket(pending));
      }
    } catch (error) {
      return thunkApi.rejectWithValue({ ...(error as ApiError) });
    }
  },
);

export const clearSyncPendingTickets = createAsyncThunk<void, void, { rejectValue: ApiError }>(
  'clearSyncPendingTickets',
  async (_: void, thunkApi) => {
    try {
      const pendings = await checkinsDBService.getPendingTicketsByState(PendingTicketState.SYNCED);
      for (const pending of pendings) {
        await checkinsDBService.deletePendingTicket(pending);
        thunkApi.dispatch(removePendingState(pending));
      }
    } catch (error) {
      return thunkApi.rejectWithValue({ ...(error as ApiError) });
    }
  },
);

export const removePendingTickets = createAsyncThunk<void, PendingTicket, { rejectValue: ApiError }>(
  'removePendingTickets',
  async (pendingTicket, thunkApi) => {
    try {
      await checkinsDBService.deletePendingTicket(pendingTicket);
      thunkApi.dispatch(removePendingState(pendingTicket));
    } catch (error) {
      return thunkApi.rejectWithValue({ ...(error as ApiError) });
    }
  },
);

export const syncTicketsFromServer = createAsyncThunk<void, void, { rejectValue: ApiError }>(
  'ticketsSlice/syncTicketsFromServer',
  async (_: void, thunkApi) => {
    const store = thunkApi.getState() as RootState;
    const toScan = store.event.toScan as Event[];

    for (const event of toScan) {
      const res = await apiGetTicketsByEventId(event.id, store.ticket.lastUpdate + 1);

      if (res.data.length > 0) {
        await ticketsDBService.upddateTickets(res.data);

        const lastUpdate = Math.max(...res.data.map((ticket) => parseInt(ticket.lastUpdate)));

        thunkApi.dispatch(setLastUpdate(lastUpdate));
      }
    }
  },
);

export const syncProcess = createAsyncThunk<void, void, { rejectValue: ApiError }>(
  'ticketsSlice/syncProcess',
  async (_: void, thunkApi) => {
    const store = thunkApi.getState() as RootState;
    const { syncProcess, syncProcessStart } = store.ticket;

    if (!syncProcess || (!syncProcessStart && syncProcessStart.getTime() < Date.now() - 1000 * 60 * 10)) {
      thunkApi.dispatch(setSyncProcess(true));
      await thunkApi.dispatch(syncPendingTickets());
      await thunkApi.dispatch(syncTicketsFromServer());
    } else {
      console.warn('Sync tickets already running');
    }
  },
);
