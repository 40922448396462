import React, { FC, useEffect } from 'react';
import { Box, Button, makeStyles, Theme, Typography } from '@material-ui/core';
import { isAfter } from 'date-fns';
import { EventRow } from '../display/event-row';
import { useHistory } from 'react-router-dom';
import { SearchInput } from '../display/search-input';
import { getToday, lessOrEqualsThanToday } from '../../util/date-utils';
import { useAppSelector } from '../../store';
import { useDispatch } from 'react-redux';
import { findEvents } from '../../store/thunks/event-thunks';
import {
  selectEventsToScanIds,
  selectIsMultipleEvents,
  selectIsOffline,
  selectOnlineModal,
} from '../../store/selectors';
import { toggleIsMultipleEvents, addOrRemoveEventToScan, setEventToScan } from '../../store/slices/event-slice';
import OnlineModal from '../display/online-modal';
import { showOnlineModal } from '../../store/slices/settings-slice';

const useStyles = makeStyles((theme: Theme) => ({
  upcoming: {
    opacity: 0.5,
  },
  subtitle: {
    fontSize: '0.9375rem',
    marginBottom: '1.25rem',
  },
  eventsContainer: {
    height: '100%',
    margin: '0 1.125rem',
    paddingTop: '1.5rem',
    paddingBottom: '3.5rem',
    overflow: 'scroll',
    paddingRight: '1.125rem',
    marginRight: '0px',
  },
  bottomBar: {
    backgroundColor: theme.palette.background.default,
    width: '100%',
    position: 'fixed',
    bottom: '0',
    display: 'flex-wrap',
    flexDirection: 'column',
    padding: '1.25rem',
  },
  selectMultEventsBtn: {
    padding: '1px 8px',
    textTransform: 'capitalize',
    fontWeight: 'bold',
  },
  offlineMessage: {
    backgroundColor: '#f0e39e',
    marginBottom: '10px',
    borderRadius: '4px',
    padding: '5px',
    color: '#b59a09',
  },
}));

export const LandingPage: FC = () => {
  const classes = useStyles();

  const { events } = useAppSelector((state) => state.event);
  const { offlineMode } = useAppSelector((state) => state.settings);
  const isMultipleEvents = useAppSelector(selectIsMultipleEvents);
  const eventsToScanIds = useAppSelector(selectEventsToScanIds);
  const isOffline = useAppSelector(selectIsOffline);
  const onlineModal = useAppSelector(selectOnlineModal);

  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(findEvents({ search: '' }));
  }, []);

  if (!events) {
    return <div>Loading...</div>;
  }

  const eventsToday = events.filter((event) => lessOrEqualsThanToday(new Date(event.start * 1000)));
  const eventsUpcoming = events.filter((event) => isAfter(new Date(event.start * 1000), getToday()));

  const handleSerchEvents = (search) => {
    dispatch(findEvents({ search }));
  };

  const onSelectEvent = (eventId: string) => {
    if (isMultipleEvents) {
      if (!offlineMode) dispatch(addOrRemoveEventToScan(eventId));
    } else {
      dispatch(setEventToScan(eventId));
      history.push(`/event/${eventId}`);
    }
  };

  const onSelectMultiplesEvents = () => {
    if (isOffline) {
      dispatch(showOnlineModal(true));
    } else {
      dispatch(toggleIsMultipleEvents());
    }
  };

  return (
    <Box display='flex' flexDirection='column' justifyContent='space-between' height='100%'>
      <Box>
        <SearchInput placeholder={'Find Events...'} handleSubmit={handleSerchEvents} />
      </Box>
      <Box className={classes.eventsContainer}>
        {events.length === 0 && (
          <>
            <Typography variant='subtitle2' className={classes.subtitle}>
              No events were found
            </Typography>
          </>
        )}

        {eventsToday.length > 0 && (
          <>
            <Box display='flex' justifyContent='space-between' alignItems='center' marginBottom='1.25rem'>
              <Typography variant='subtitle2' className={classes.subtitle}>
                Today
              </Typography>
              <Button
                className={classes.selectMultEventsBtn}
                onClick={onSelectMultiplesEvents}
                variant={isMultipleEvents ? 'outlined' : 'contained'}
                color='primary'
              >
                Select Multiple Events {isMultipleEvents ? 'X' : ''}
              </Button>
            </Box>
            {eventsToday.map((event) => (
              <Box onClick={() => onSelectEvent(event.id)} key={event.id}>
                <EventRow
                  weekday={true}
                  event={event}
                  displayDate={getToday()}
                  isMultipleEvents={isMultipleEvents}
                  isSelected={eventsToScanIds.includes(event.id)}
                  disabled={offlineMode}
                />
              </Box>
            ))}
          </>
        )}
        {eventsUpcoming.length > 0 && (
          <>
            <Typography variant='subtitle2' className={classes.subtitle}>
              Upcoming
            </Typography>
            <div className={classes.upcoming}>
              {eventsUpcoming.map((event) => (
                <Box key={event.id} onClick={() => onSelectEvent(event.id)}>
                  <EventRow
                    weekday={true}
                    event={event}
                    displayDate={new Date(event.start * 1000)}
                    isMultipleEvents={isMultipleEvents}
                    isSelected={eventsToScanIds.includes(event.id)}
                    disabled={offlineMode}
                  />
                </Box>
              ))}
            </div>
          </>
        )}
      </Box>
      <OnlineModal
        open={onlineModal}
        description='In order to select different events you will have to go back to Online Mode and sync all data'
      />

      <Box className={classes.bottomBar}>
        {isOffline && (
          <Box className={classes.offlineMessage}>
            <Typography variant='subtitle2'>You are Offline</Typography>
          </Box>
        )}
        {isMultipleEvents && eventsToScanIds?.length > 0 && (
          <Button onClick={() => history.push('/event/multi-events')} fullWidth color='primary' variant='contained'>
            Continue to Check-In
          </Button>
        )}
      </Box>
    </Box>
  );
};
