import React, { FC } from 'react';
import { Box, makeStyles, Theme, Typography } from '@material-ui/core';
import { ArrowRightWhiteIcon, SuccessWhiteIcon } from '../../assets';

const useStyles = makeStyles((theme: Theme) => ({
  successContainer: {
    width: '100%',
    padding: '5px',
    borderRadius: '3px',
    backgroundColor: theme.palette.success.main,
    marginTop: '10px',
    display: 'flex',
    justifyContent: 'center',
    gap: '5px',
    color: '#ffffff',
    boxShadow: 'none',
    fontSize: '12px',
    fontWeight: 'bold',
    alignItems: 'center',
    marginBottom: '1rem',
  },
  successIcon: {
    marginRight: '1.5rem',
    marginTop: '2px',
  },
  detailsContainer: {
    marginLeft: '1.5rem',
    display: 'flex',
    alignItems: 'center',
    gap: '0.5rem',
  },
  checkinText: {
    fontSize: '15px',
  },
}));

interface SuccessNotificationProps {
  label: string;
  goToScannedEvent: () => void;
}

export const SuccessNotification: FC<SuccessNotificationProps> = ({ goToScannedEvent, label }) => {
  const classes = useStyles();

  return (
    <Box className={classes.successContainer}>
      <Box className={classes.successIcon}>
        <SuccessWhiteIcon />
      </Box>
      <Typography variant='body2' className={classes.checkinText}>
        {label}
      </Typography>
      <Box onClick={goToScannedEvent} className={classes.detailsContainer}>
        <Typography>Details</Typography>
        <ArrowRightWhiteIcon />
      </Box>
    </Box>
  );
};

export default SuccessNotification;
