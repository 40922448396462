import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '..';
import { ApiError } from '../../model/common-types';
import { GenericMessage } from '../../model/constants';
import { ThemeSettings } from '../../model/theme-settings';
import { Event } from '../../model/tsp/event';
import { TicketResponse } from '../../model/tsp/ticket';
import { apiGetTicketsByEventId, apiGetTicktsTypes, apiLoadSettings } from '../../service/api-service';
import { checkinsDBService, db } from '../../service/db-service';
import { getHexColorCss } from '../../util/common-utils';
import { syncPendingTickets } from './ticket-thunks';

export const loadSettings = createAsyncThunk<ThemeSettings, void, { rejectValue: ApiError }>(
  'loadSettings',
  async (_: void, thunkApi) => {
    try {
      const res = await apiLoadSettings();
      if (res.success) {
        return {
          headerBackground: getHexColorCss(res.data.mainTopBarColor),
          logoUrl: res.data.adminHeaderPicture,
          siteName: res.data.siteName,
          accentColor: getHexColorCss(res.data.ticketClaimEmailHeaderBackground),
        } as ThemeSettings;
      } else {
        return thunkApi.rejectWithValue({ code: 400, message: res.message });
      }
    } catch (error) {
      return thunkApi.rejectWithValue(error as ApiError);
    }
  },
);

export const setOfflineMode = createAsyncThunk<string[], string[], { rejectValue: ApiError }>(
  'setOfflineMode',
  async (eventIds: string[], thunkApi) => {
    try {
      const state = thunkApi.getState() as RootState;
      await db.resetDatabase();

      for (const eventId of eventIds) {
        const event = (await state.event.events.find((ev) => ev.id === eventId)) as Event;
        await db.initializeOffline(event);
        await apiGetTicketsByEventId(eventId, undefined, async (res: TicketResponse): Promise<void> => {
          await db.tickets.bulkAdd(res.data);
        });
        const ticketTypes = await apiGetTicktsTypes(eventId);
        const mapTicketTypes = ticketTypes.data.map((type) => ({ id: type.id, name: type.name, eventId }));
        db.offlineTicketTypes.bulkAdd(mapTicketTypes);
      }

      thunkApi.dispatch(existsPendingsWithErrors());

      return eventIds;
    } catch (error) {
      return thunkApi.rejectWithValue({
        code: 400,
        message: error?.message || GenericMessage.UnknownError,
      } as ApiError);
    }
  },
);

export const existsPendingsWithErrors = createAsyncThunk<number, void, { rejectValue: ApiError }>(
  'existsPendingsWithErrors',
  async () => {
    return await checkinsDBService.existsPendingsWithErrors();
  },
);

export const setOnlineMode = createAsyncThunk<string[], string[], { rejectValue: ApiError }>(
  'setOnlineMode',
  async (eventIds: string[], thunkApi) => {
    try {
      await thunkApi.dispatch(syncPendingTickets());

      return eventIds;
    } catch (error) {
      return thunkApi.rejectWithValue(error as ApiError);
    }
  },
);
