import { Ticket } from './ticket';

export interface FindOrders {
  eventIds?: string[];
  search?: string;
  skip?: number;
  take?: number;
}
export interface FindOrdersQuery {
  eventId?: string;
  search?: string;
  skip?: number;
  take?: number;
}
export interface OrderByIdResponse {
  success: boolean;
  message: string;
  data: Order;
}

export interface Order {
  id: string;
  created: string;
  hash: string;
  tickets: TicketObj;
}

export interface TicketObj {
  data: Ticket[];
  count: number;
  totalCount: number;
  offset: number;
  limit: number;
}

export enum ProcessState {
  Purchased = '0',
  CheckedIn = '2',
  Cancelled = '4',
}

export interface SearchOrders {
  success: boolean;
  message: null;
  data: SearchOrderDetails[];
  count: number;
  totalCount: string;
  limit: number;
}

export interface SearchOrderDetails {
  orderId: string;
  userId: string;
  externalId: string;
  orderHash: string;
  purchaseDate: string;
  ticketCount: string;
}

export interface WaveTime {
  id: string;
  description: string;
  startTimestamp: string;
  formattedStartTime: string;
}
