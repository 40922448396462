import { Box, Checkbox, Grid, makeStyles } from '@material-ui/core';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import React from 'react';

import { Event } from '../../model/tsp/event';
import { EventCalendar } from './event-calendar';
import { EventTitle } from './event-title';

const useStyles = makeStyles((theme) => ({
  cardHeader: {
    background: theme.palette.warning.main,
    padding: 0,
    textAlign: 'center',
  },
  cardContent: {
    padding: 0,
    textAlign: 'center',
    '&:last-child': {
      paddingBottom: 0,
    },
  },
  arrowIcon: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: '0.9375rem',
  },
}));

interface EventRowProps {
  event: Event;
  weekday: boolean;
  displayDate: Date;
  isMultipleEvents: boolean;
  isSelected?: boolean;
  disabled?: boolean;
}

export const EventRow = ({
  event,
  weekday,
  displayDate,
  isSelected,
  isMultipleEvents,
  disabled = false,
}: EventRowProps) => {
  const classes = useStyles();

  return (
    <Box marginBottom='1.25rem'>
      <Grid container alignItems='center'>
        <Grid item xs={4} md={2}>
          <EventCalendar weekday={weekday} date={displayDate} />
        </Grid>
        <Grid item xs={7} md={8}>
          <EventTitle event={event} />
        </Grid>
        <Grid item xs={1} md={2}>
          {!isMultipleEvents && (
            <Box textAlign='right'>
              <ArrowForwardIosIcon className={classes.arrowIcon} />
            </Box>
          )}
          {isMultipleEvents && (
            <Box textAlign='right'>
              <Checkbox checked={isSelected} name='checkedB' color='primary' disabled={disabled} />
            </Box>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};
